<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
  import { processFetchedRoutes } from "@/utils/processFetchedRoutes"
  import isMobile from "@/utils/device"
  import isWeixin from "@/utils/device"
  import { removeToken, getToken } from '@/utils/token'
  import { fetchRoutersRequest } from "@/network/api/login"
  import { fetchUserInfo } from "@/network/api/login"
  import { routesSessionStorage } from "@/utils/storage"
  export default {
    name: 'app',
    async created () {

      let deviceType = ''
      if(isMobile() || isWeixin()) {
        deviceType = 'mobile'
      } else {
        deviceType = 'pc'
      }

      // 在页面加载时读取sessionStorage
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
      }
      // 在页面刷新时将store保存到sessionStorage里
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })

      // 页面打开时，检查是否存在token，然后分情况处理
      let hasToken = !!getToken()

      if (!hasToken) { // 如果没有token，则一并清除其他可能存在的数据
        sessionStorage.clear()
        this.$store.commit('SET_LOGOUT')
        this.$store.commit('user/CLEAR_USER_INFO')
        this.$store.commit('router/CLEAR_ROUTES')
      }

      if (hasToken) { // 如果有token，检查其他数据是否正常，若不正常则获取 / 重设
        if (!sessionStorage.getItem("routes")){
          try {
            const data = {
              'device_type': deviceType
            }
            const response = await fetchRoutersRequest(data)
            const status = response.status
            if(status == 200){
              const fetchedRoutes = response.data.routers
              routesSessionStorage(fetchedRoutes)
            }else {
              this.$message.error('登录状态过期，请重新登录')
              removeToken()
              this.$router.push('/login-entry')
            }
          } catch (error) {
            this.$message.error('登录状态过期，请重新登录')
            removeToken()
            this.$router.push('/login-entry')
          }
        }
        if (this.$store.state.user.uid == '' || this.$store.state.user.username == '') {
          try {
            const response = await fetchUserInfo()
            const status = response.status
            if(status == 200){
              const userInfo = {
                'uid': response.data.uid,
                'username': response.data.username
              }
              this.$store.commit('user/SET_INFO', userInfo)
            }else {
              this.$message.error('登录状态过期，请重新登录')
              removeToken()
              this.$router.push('/login-entry')
            }
          } catch (error) {
            this.$message.error('登录状态过期，请重新登录')
            removeToken()
            this.$router.push('/login-entry')
          }
        }
        if (this.$store.state.user.token != getToken()) {
          this.$store.commit("user/SET_TOKEN", getToken())
        }
        if (this.$store.state.isLogin == false) {
          this.$store.commit("SET_LOGIN")
        }
      }

      // 页面打开时，将初始路由保存到store中
      this.$store.commit('router/SET_INIT_ROUTES')
      // 页面打开时，将设备类型保存到store中
      this.$store.commit('SET_DEVICE_TYPE', deviceType)

      // 在页面刷新时重新挂载认证后取到的权限路由
      if (sessionStorage.getItem("routes")) {
        let routes = JSON.parse(sessionStorage.getItem("routes"));
        processFetchedRoutes(routes);
        this.$router.selfAddRoutes(routes);
        this.$store.commit('router/SET_ROUTES', routes);
      }
      // else if (localStorage.getItem("routes")) {
      //   let routes = JSON.parse(localStorage.getItem("routes"));
      //   processFetchedRoutes(routes);
      //   this.$router.selfAddRoutes(routes);
      //   this.$store.commit('router/SET_ROUTES', routes);
      // }
    },
    provide() {
      return {
        reload: this.reload
      }
    },
    data () {
      return {
        isRouterAlive: true
      }
    },
    methods: {
      reload: function () {
        this.isRouterAlive = false
        this.$nextTick(function () { 
          this.isRouterAlive = true
        })
        }
    }
  }
</script>

<style lang="scss">
  @import url("./assets/css/main.css");
  @import url("./assets/css/color-dark.css");
  body {
    margin:0;
    padding: 0;
  }
</style>
